<template>
  <div class="managingPeople hp100 bbox" v-loading="loading">
    <input
      type="file"
      accept=".XLSX,.XLS,.XML"
      class="uploadFile"
      style="display:none"
      @change="fileChange"
    />
    <!-- 卡片视图 -->
    <!-- <el-card> -->
    <!-- 搜索-->
    <div style="display:flex;justify-center:space-between">
      <div style="display:flex">
<!--        <el-button-->
<!--          plain-->
<!--          class="add-column-btn bbox"-->
<!--          @click="gotoDetail('')"-->
<!--          type="primary"-->
<!--          style="margin-right:40px"-->
<!--        >-->
<!--          <i class="el-icon-plus fw900 f16"></i> 新增</el-button-->
<!--        >-->
        <v-distpicker
          style="margin-top:-4px"
          v-model="text"
          @province="onChangeProvince"
          @city="onChangeCity"
          @area="onChangeArea"
        ></v-distpicker>
      </div>
      <div style="margin-left:20px">
        <el-input
          style="width: 300px;"
          placeholder="请输入运营商、管理员名称"
          clearable
          v-model="searchInput"
          class="header-search-input ml10"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            class="textblue searchBtn"
            @click="search()"
          ></el-button>
        </el-input>
      </div>
<!--      <div style="margin-left: 40px">-->
<!--        <a :href="downloadUrl" class="downloadFile"></a>-->
<!--        <el-button type="primary" @click="goDownload">下载导入模板</el-button>-->
<!--      </div>-->
    </div>
    <!-- 用户列表区域 -->
    <el-table
      :data="tableData"
      border
      fit
      :height="height"
      highlight-current-row
      style="width: 100%"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="编号" type="index" width="50">
      </el-table-column>
      <el-table-column label="省市区" align="center" prop="memberName">
        <template slot-scope="scope">
          <span>{{ scope.row.area }}</span>
        </template>
      </el-table-column>
      <el-table-column label="电子围栏名称" align="center" prop="memberName">
        <template slot-scope="scope">
          <span>{{ scope.row.electronicFenceName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属运营商" align="center" prop="orderPrice">
        <template slot-scope="scope">
          <span>{{ scope.row.operatorName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属管理员" align="center" prop="orderPrice">
        <template slot-scope="scope">
          <span>{{ scope.row.administratorName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="" align="center" prop="id" v-if="false">
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" prop="orderPrice">
        <template slot-scope="scope">
          <span v-if="scope.row.fenceBuildingStatus == 0" style="color: skyblue">建设中</span>
          <span v-if="scope.row.fenceBuildingStatus == 1" style="color: green">运行</span>
          <span v-if="scope.row.fenceBuildingStatus == 2" style="color: red">维修</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="memberCode">
        <template slot-scope="scope">
            <span v-if="scope.row.createDt">{{scope.row.createDt | dataFormat }}</span>
          <span v-else>---</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        fit="true"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            content="编辑"
            placement="bottom"
          >
            <i
              class="el-icon-edit textblue f16 ml20"
              @click="gotoDetail(scope.row.id)"
            ></i>
          </el-tooltip>

<!--          <el-tooltip-->
<!--            class="item"-->
<!--            effect="dark"-->
<!--            content="删除"-->
<!--            placement="bottom"-->
<!--          >-->
<!--            <i-->
<!--              class="el-icon-delete textblue f16 ml20"-->
<!--              @click="del(scope.row.id)"-->
<!--            ></i>-->
<!--          </el-tooltip>-->

<!--          <el-tooltip-->
<!--            class="item"-->
<!--            effect="dark"-->
<!--            content="导入设备"-->
<!--            placement="bottom"-->
<!--          >-->
<!--            &lt;!&ndash; <el-upload-->
<!--              :show-file-list="false"-->
<!--              class="upload-demo inline-block"-->
<!--              ref="uploadF"-->
<!--              :action="$uploadURL"-->
<!--              :on-success="uploadSuccess"-->
<!--              :on-error="uploadError"-->
<!--              accept=".XLSX,.xml,.XML,"-->
<!--            > &ndash;&gt;-->
<!--            <i-->
<!--              class="el-icon-circle-plus-outline textblue f16 ml20"-->
<!--              @click="getId(scope.row)"-->
<!--            ></i>-->
<!--            &lt;!&ndash; </el-upload> &ndash;&gt;-->
<!--          </el-tooltip>-->
        </template>
      </el-table-column>
    </el-table>
    <div class="footerBox" style="margin-top:20px">
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryInfo.currPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- </el-card> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight - 256, //表格高度
      value: "",
      operatorList: [],
      tableData: [],
      deviceData: [],
      idList: [],
      fileList: [],
      loading: true,
      value1: "",
      id: "",
      text: "",
      txt1: "",
      txt2: "",
      txt3: "",
      adminObj: "",
      operatorName: "",
      electronicFenceCode: "",
      operatorObj: "",
      searchInput: "",
      importUrl: "",

      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 10,
        //查询条件
        condition: {
          id: "",
          area: "",
          keyWord: ""
        }
      },
      userlist: [],
      multipleSelection: [],
      total: 0,
      // 修改用户
      editDialogVisible: false,
      dialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false,
      theForm: {},
      file: {},
      fileUrl: "",
      downloadUrl: ""
    };
  },
  created() {
    // this.queryInfo.page=this.$route.query.page;
    //  this.queryInfo.limit=this.$route.query.limit;
    console.log("------" + this.$uploadURL);
    this.getListData();
    this.loadOperator();
    this.loadAdmin();
    window.addEventListener("resize", this.getHeight);
    this.getDownloadUrl();
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    goDownload() {
      document.querySelector(".downloadFile").click();
    },
    // 获取模板连接
    getDownloadUrl() {
      this.$http
        .post("/systemConfig/queryOne", { propertyKey: "FILEPATH" })
        .then(res => {
          if (res.data.code == 200) {
            this.downloadUrl = res.data.data.propertyValue;
          }
        });
    },
    onChangeProvince(a) {
      console.log(a);
      if (a.value == "省") {
        this.txt1 = "";
      } else {
        this.txt1 = a.value + ",";
      }
    },
    onChangeCity(a) {
      if (a.value == "市") {
        this.txt2 = "";
      } else {
        this.txt2 = a.value + ",";
      }
    },
    onChangeArea(a) {
      if (a.value == "区") {
        this.txt3 = "";
      } else {
        this.txt3 = a.value + ",";
      }
    },
    getId(row) {
      const btn = document.querySelector(".uploadFile");
      this.theForm = {
        electronicFenceCode: row.electronicFenceCode,
        electronicFenceName: row.electronicFenceName,
        operatorCode: row.operatorCode,
        operatorName: row.operatorName,
        administratorCode: row.administratorCode,
        administratorName: row.administratorName
      };
      btn.click();
      console.log(row.electronicFenceCode);
      this.electronicFenceCode = row.electronicFenceCode;
    },
    // 文件改变
    fileChange(e) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      // 首先将文件上传到服务器
      this.$http.post("upload/uploadFile", formData).then(res => {
        if (res.data.code == 200) {
          this.fileUrl = res.data.data.url;
          // 将服务器返回的数据作为参数提交
          this.$http
            .post("//device/importExcel", {
              filePath: res.data.data.localPath,
              electronicFenceCode: this.theForm.electronicFenceCode,
              electronicFenceName: this.theForm.electronicFenceName,
              operatorCode: this.theForm.operatorCode,
              operatorName: this.theForm.operatorName,
              administratorCode: this.theForm.administratorCode,
              administratorName: this.theForm.administratorName,
              fileName: res.data.data.fileName
            })
            .then(res => {
              if (res.data.code == 200) {
                if (res.data.data.result == 1) {
                  this.$notify.success({
                    title: "消息",
                    message: "导入成功",
                    showClose: true
                  });
                } else {
                  this.$notify.success({
                    title: "消息",
                    message: "导入失败",
                    showClose: true
                  });
                }
              }
            });
        }
      });

      // 每次提交过后都将数据清空，不然无法再次触发change
      document.querySelector(".uploadFile").value = "";
    },
    //条件查询
    search() {
      this.getListData();
    },
    uploadSuccess(response, file, filelist) {
      // this.$refs.uploadF.clearFiles();
      var that = this;
      console.log("上传成功返回图片");
      // console.log(response.data.url);
      that.importUrl = response.data.url;
      // console.log(that.electronicFenceCode);
      // console.log(file.response.data.url);
      that.$http
        .post("/electronicFence/importDevice", {
          electronicFenceCode: that.electronicFenceCode,
          url: that.importUrl
        })
        .then(function(response) {
          that.$notify.success({
            title: "提示",
            message: "导入成功",
            showClose: true
          });
          // that.$router.go(-1);
        })
        .catch(function(error) {
          that.$notify.info({
            title: "提示",
            message: "导入失败",
            showClose: true
          });
        });
    },
    uploadError() {
      console.log("-----");
      // this.$refs.uploadF.clearFiles();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },
    // 监听排序
    sortChange() {},
    //跳转到详情
    gotoDetail(id) {
      var that = this;
      this.$router.push({
        path: "/equipment/equipDetails",
        query: {
          id: id,
          page: that.queryInfo.page,
          limit: that.queryInfo.limit
        }
      });
    },
    //导入电子围栏
    add(id) {},
    loadOperator() {
      var that = this;
      that.$http.post("/userOperator/queryAll", {}).then(function(response) {
        if (response.data.code == 200) {
          that.operatorList = response.data.data;
        }
      });
    },
    chooseOperator(value) {
      let that = this;
      that.queryInfo.condition.area = value;
      that.$http
        .post("/electronicFence/list", {
          currPage: that.queryInfo.currPage,
          pageSize: that.queryInfo.pageSize,
          condition: {
            area: that.queryInfo.condition.area
          }
        })
        .then(function(response) {
          if (response.data.code == 200) {
            that.tableData = response.data.data.data;
          }
        });
    },
    getIdList() {
      let that = this;
      let tempList = [];
      that.tableData.forEach(item => {
        tempList.push(item.area);
      });
      for (var value of tempList) {
        // console.log(value);
        that.prov = value.split(",")[0];
        that.cty = value.split(",")[1];
        that.dit = value.split(",")[2];
        // console.log(that.prov);
      }
    },
    loadAdmin() {
      var that = this;
      that.$http.post("/userAdmin/queryList", {}).then(function(response) {
        if (response.data.code == 200) {
          that.adminList = response.data.data;
        }
      });
    },
    // 删除用户
    async del(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/electronicFence/delete", { id: id })
          .then(function(response) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true
            });
            that.getListData();
          });
      }
    },
    getListData() {
      // 获取列表
      var that = this;
      if (that.txt1 == "") {
        that.queryInfo.condition.area = "";
      } else if (that.txt2 == "") {
        that.queryInfo.condition.area = that.txt1;
      } else {
        that.queryInfo.condition.area = that.txt1 + that.txt2 + that.txt3;
      }
      that.queryInfo.condition.area = that.queryInfo.condition.area.slice(
        0,
        that.queryInfo.condition.area.length - 1
      );
      that.queryInfo.condition.keyWord = that.searchInput;
      that.queryInfo.condition.operatorCode = JSON.parse(localStorage.getItem('userInfo')).operatorCode
      var data = that.queryInfo;
      //   console.log(data)
      that.$http
        .post("/electronicFence/listOperator", {
          currPage: data.currPage,
          pageSize: data.pageSize,
          condition: that.queryInfo.condition
        })
        .then(function(response) {
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.tableData = response.data.data.data;
            console.log(that.tableData);
            that.getIdList();
          }
        });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    }
  }
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}
.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
    .el-select > .el-input {
      width: 200px;
    }
    // .header-search-box .header-search-input {
    //     width: 325px;
    // }
    input::placeholder {
      font-size: 12px;
    }
    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }
    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
.inline-block {
  display: inline-block;
}
.mr20 {
  margin-right: 40px;
}
</style>
